.table table {
  border-collapse: collapse;
}

.table th,
td {
  padding: 8px;
}

.table th {
  background-color: #66cc02;
  color: white;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}
